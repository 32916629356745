import styled from "styled-components";

import BonusBox from "./Bonuses/Bonus";

const StyledHeaderText = styled.div`
  margin-bottom: 70px;
  color: #e1489c;
  background-color: #f2a9db;
  font-size: 10em;
  padding: 0.2em 0 0.2em 0;
  font-family: "Handlee", cursive;
  font-weight: 900;
  font-style: normal;
  /* text-shadow: 0 0 1px #78bcc4, 0 0 1px #78bcc4, 0 0 16px #78bcc4,
    0 0 30px #2b2c31, 0 0 52px #2b2c31, 0 0 62px #2b2c31, 0 0 82px #2b2c31,
    0 0 111px #2b2c31; */
  @media (max-width: 800px) {
    font-size: 2em;
  }
`;

const HeaderText = () => {
  return <StyledHeaderText>ParnianDS.com</StyledHeaderText>;
};

const HeaderBar = styled.div`
  background: linear-gradient(180deg, #f2a9db 40%, #fcfdff 100%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-family: "Geologica", sans-serif;
  ${({ height }) =>
    height &&
    `
    height: ${height}vh;
  `}
`;

const FeaturedWrapper = styled.div`
  max-width: 950px;
  margin: 2em auto;
  color: #78bcc4;
  box-shadow: 0 0 50px 10px #d8a4db;
  border-radius: 8px;
  font-family: "Rajdhani", sans-serif;

  @media (max-width: 800px) {
    margin: 2em 0.5em;
  }
`;

const SocialLinks = styled.div`
  width: 140px;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 0;

  @media (max-width: 800px) {
  }
`;

const SingleSocialLink = styled.a`
  /* width: 40px;
  height: 40px; */
  margin: 5px;
`;

const RaffleButton = styled.a`
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  padding: 15px;
  background-color: #fff;
  color: #d8a4db;
  cursor: pointer;
`;

const MobileHeaderSection = styled.div`
  background-color: #f2a9db;
  @media (max-width: 800px) {
    height: 60px;
  }
`;

const LandingPage = () => {
  return (
    <HeaderBar height={100}>
      <HeaderText />
    </HeaderBar>
  );
};

const FeaturedBonus = ({ bonus }) => {
  return (
    <FeaturedWrapper>
      <BonusBox index={"featured"} bonus={bonus} featured={true} />
    </FeaturedWrapper>
  );
};

const Header = ({ featuredBonus }) => {
  return (
    <HeaderBar>
      <MobileHeaderSection>
        <SocialLinks>
          <SingleSocialLink
            href="https://www.twitch.tv/parniands"
            target="_blank"
          >
            <svg width="24" height="24">
              <path
                d="M2.149 0l-1.612 4.119v16.836h5.731v3.045h3.224l3.045-3.045h4.657l6.269-6.269v-14.686h-21.314zm19.164 13.612l-3.582 3.582h-5.731l-3.045 3.045v-3.045h-4.836v-15.045h17.194v11.463zm-3.582-7.343v6.262h-2.149v-6.262h2.149zm-5.731 0v6.262h-2.149v-6.262h2.149z"
                fill-rule="evenodd"
                clip-rule="evenodd"
                fill="#ffffff"
              />
            </svg>
          </SingleSocialLink>
          <SingleSocialLink
            href="https://www.instagram.com/parniands/"
            target="_blank"
          >
            <svg width="24" height="24">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                  fill="#ffffff"
                ></path>{" "}
                <path
                  d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
                  fill="#ffffff"
                ></path>{" "}
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"
                  fill="#ffffff"
                ></path>{" "}
              </g>
            </svg>
          </SingleSocialLink>
          <SingleSocialLink
            href="https://discord.gg/f8vEmFkcGQ"
            target="_blank"
          >
            <svg
              viewBox="0 -28.5 256 256"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid"
              fill="#ffffff"
              width="24"
              height="24"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                  {" "}
                  <path
                    d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
                    fill="#ffffff"
                    fill-rule="nonzero"
                  >
                    {" "}
                  </path>{" "}
                </g>{" "}
              </g>
            </svg>
          </SingleSocialLink>
          <SingleSocialLink
            href="https://www.tiktok.com/@parniands"
            target="_blank"
          >
            <svg
              fill="#ffffff"
              viewBox="0 0 32 32"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <title>tiktok</title>{" "}
                <path d="M16.656 1.029c1.637-0.025 3.262-0.012 4.886-0.025 0.054 2.031 0.878 3.859 2.189 5.213l-0.002-0.002c1.411 1.271 3.247 2.095 5.271 2.235l0.028 0.002v5.036c-1.912-0.048-3.71-0.489-5.331-1.247l0.082 0.034c-0.784-0.377-1.447-0.764-2.077-1.196l0.052 0.034c-0.012 3.649 0.012 7.298-0.025 10.934-0.103 1.853-0.719 3.543-1.707 4.954l0.020-0.031c-1.652 2.366-4.328 3.919-7.371 4.011l-0.014 0c-0.123 0.006-0.268 0.009-0.414 0.009-1.73 0-3.347-0.482-4.725-1.319l0.040 0.023c-2.508-1.509-4.238-4.091-4.558-7.094l-0.004-0.041c-0.025-0.625-0.037-1.25-0.012-1.862 0.49-4.779 4.494-8.476 9.361-8.476 0.547 0 1.083 0.047 1.604 0.136l-0.056-0.008c0.025 1.849-0.050 3.699-0.050 5.548-0.423-0.153-0.911-0.242-1.42-0.242-1.868 0-3.457 1.194-4.045 2.861l-0.009 0.030c-0.133 0.427-0.21 0.918-0.21 1.426 0 0.206 0.013 0.41 0.037 0.61l-0.002-0.024c0.332 2.046 2.086 3.59 4.201 3.59 0.061 0 0.121-0.001 0.181-0.004l-0.009 0c1.463-0.044 2.733-0.831 3.451-1.994l0.010-0.018c0.267-0.372 0.45-0.822 0.511-1.311l0.001-0.014c0.125-2.237 0.075-4.461 0.087-6.698 0.012-5.036-0.012-10.060 0.025-15.083z"></path>{" "}
              </g>
            </svg>
          </SingleSocialLink>
        </SocialLinks>
        <RaffleButton>Raffle (coming soon)</RaffleButton>
      </MobileHeaderSection>
      <HeaderText />
      {featuredBonus ? (
        <>
          <FeaturedBonus bonus={featuredBonus[0]} />
        </>
      ) : null}
    </HeaderBar>
  );
};

export { HeaderText, HeaderBar, LandingPage, Header };
