// import Energy from "./images/energy.png";
import SGCasino from "./images/sgcasino.png";
import Wazamba from "./images/wazamba2.png";
import Slotbox from "./images/slotbox.png";
import Ursa from "./images/ursa.jpg";
import Legiano from "./images/legiano.png";
import Cazimbo from "./images/cazimbo.png";
import Spinrollz from "./images/spinrollz.png";
import Gomblingo from "./images/gomblingo.png";
import Vauhdikas from "./images/vauhdikas.png";
import Casimba from "./images/casimba.png";
import Pelikaani from "./images/pelikaani.png";
import Wildz from "./images/wildz.png";
import Chipz from "./images/chipz.png";
import Flappy from "./images/flappy.png";
import Frumzi from "./images/frumzi.png";
import Nova from "./images/novajackpot.png";
import None from "./images/n1casino.png";

const bonuses = [
  // {
  //   name: "energy",
  //   type: "bonus",
  //   img: Energy,
  //   link: "https://charity.energy.partners/redirect.aspx?pid=50091&lpid=1210&bid=5799",
  //   payNplay: true,
  //   highlight: true,
  //   license: "MGA",
  //   perks: {
  //     bonus: {
  //       maxSum: 300,
  //       percentage: 100,
  //     },
  //     freeSpins: {
  //       value: 300,
  //     },
  //     wager: { value: 30 },
  //   },
  // },
  {
    name: "novajackpot",
    type: "bonus",
    img: Nova,
    link: "https://hub.buzzaffiliates.com/visit/?bta=35918&brand=novajackpot",
    license: "Curacao",
    highlight: true,
    nonSticky: true,
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "vauhdikas",
    type: "bonus",
    img: Vauhdikas,
    link: "https://afftrackcf.21.partners/C.ashx?btag=a_18754b_1099c_&affid=4426&siteid=18754&adid=1099&c=",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 300,
        percentage: 100,
      },
      freeSpins: {
        value: 100,
      },
      wager: {
        value: 40,
        condition: "CODE: VAUH",
      },
    },
  },
  {
    name: "n1",
    type: "bonus",
    img: None,
    link: "http://url1009.softswiss-service.com/ls/click?upn=u001.Ygo2sEeJ8-2Fx-2BG91Z6c7jCE1ZER3N-2Fx1y7KoBAZnKUcAsO9CVaLVVdUcgUor-2Bip9zB3rj_-2FZ2voozZI-2FoRtG4SqHh5mTv3j02offQC97bPOAIGehwBhM7lgW2YZVD8lk2CdUJaTdONAb0CSNmOiTMFTg6HoP-2B6rx0eV-2BfMjbViRiIeq6unc18gI-2FgZJqd7-2BOaDcyMKOzkSlDZlSD3Y8tzWkkY-2B7FQewQQIS3hBAKHSzvqKFlTIPRhgcHYHUAglZUxRaEe5BUlfmUvzl33DJz6XutDgPHYq3ey9ccENRNQ-2BrREwMhs-3D",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 1000,
        percentage: 100,
      },
      freeSpins: {
        value: 150,
      },
      wager: {
        value: 50,
      },
    },
  },
  {
    name: "frumzi",
    type: "bonus",
    img: Frumzi,
    link: "https://frm.servclick1move.com/?mid=232226_1382626",
    license: "Curacao",
    nonSticky: true,
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: {
        value: 35,
      },
    },
  },
  {
    name: "slotbox",
    type: "bonus",
    img: Slotbox,
    link: "https://bonus.slotbox.com/fi-parniands-1/",
    license: "Estonia",
    perks: {
      bonus: {
        maxSum: 100,
        percentage: 100,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "casibma",
    type: "bonus",
    img: Casimba,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_36183b_861c_&affid=6969&siteid=36183&adid=861&c=",
    license: "MGA",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 5000,
        percentage: 100,
      },
      freeSpins: {
        value: 50,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "pelikaani",
    type: "bonus",
    img: Pelikaani,
    link: "https://go.moonrocketaffiliates.com/visit/?bta=35509&brand=pelikaani&utm_campaign=parniands",
    payNplay: true,
    license: "Estonia",
    perks: {
      freeSpins: {
        value: 1,
        condition: "Superspin",
      },
      wager: {
        value: 1,
      },
    },
  },
  {
    name: "wildz",
    type: "bonus",
    img: Wildz,
    link: "https://go.rootzaffiliate.com/visit/?bta=903431&nci=5988",
    payNplay: true,
    nonSticky: true,
    license: "MGA",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      wager: { value: 35 },
      freeSpins: {
        value: 200,
      },
    },
  },
  {
    name: "flappy",
    type: "bonus",
    img: Flappy,
    link: "https://record.winmaniacs.com/_W3_qG1AdETBD3TEmsPWI0WNd7ZgqdRLk/1/",
    nonSticky: true,
    license: "Curacao",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      wager: { value: 50 },
      freeSpins: {
        value: 50,
      },
    },
  },
  {
    name: "chipz",
    type: "bonus",
    img: Chipz,
    link: "https://go.rootzaffiliate.com/visit/?bta=903431&nci=5931",
    payNplay: true,
    license: "MGA",
    perks: {
      wager: { value: 0 },
      freeSpins: {
        value: 100,
      },
    },
  },
  {
    name: "ursa",
    type: "bonus",
    img: Ursa,
    link: "https://ivyaffsolutions.com/C.ashx?btag=a_35581b_853c_&affid=6969&siteid=35581&adid=853&c=",
    license: "Isle of Man",
    payNplay: true,
    perks: {
      bonus: {
        maxSum: 200,
        percentage: 100,
      },
      freeSpins: {
        value: 50,
      },
      wager: { value: 40 },
    },
  },
  {
    name: "legiano",
    type: "bonus",
    img: Legiano,
    link: "https://lgno.servclick1move.com/?mid=226818_1350737",
    license: "PAGCOR",
    nonSticky: true,
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "cazimbo",
    type: "bonus",
    img: Cazimbo,
    link: "https://track.affilirise.com/visit/?bta=656509&nci=5395&afp=",
    payNplay: true,
    nonSticky: true,
    license: "PAGCOR",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 35 },
    },
  },
  {
    name: "spinrollz",
    type: "bonus",
    img: Spinrollz,
    link: "https://track.affilirise.com/visit/?bta=656509&nci=5540&afp=",
    payNplay: true,
    nonSticky: true,
    license: "PAGCOR",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 100,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 40 },
    },
  },
  {
    name: "gomblingo",
    type: "bonus",
    img: Gomblingo,
    link: "https://track.affilirise.com/visit/?bta=656509&nci=5477&afp=",
    payNplay: true,
    license: "Curacao",
    perks: {
      bonus: {
        maxSum: 500,
        percentage: 150,
      },
      freeSpins: {
        value: 200,
      },
      wager: { value: 40, condition: "Code: GOBLIONGOLD200" },
    },
  },
  {
    name: "sgcasino",
    type: "bonus",
    img: SGCasino,
    link: "https://sgc.servclick1move.com/?mid=226818_1350741",
    perks: {
      freeSpins: { value: 200 },
      wager: { value: 35 },
      bonus: { percentage: 100, maxSum: 500 },
    },
    license: "Curacao",
    payNplay: true,
  },
  {
    name: "wazamba",
    type: "bonus",
    img: Wazamba,
    link: "https://wzbw.servclick1move.com/?mid=226818_1350742",
    perks: {
      freeSpins: { value: 200 },
      wager: { value: 35 },
      bonus: { percentage: 100, maxSum: 500 },
    },
    license: "Estonia",
    payNplay: true,
  },
];

export default bonuses;
